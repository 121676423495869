body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mediaqueue {
    right: 0;
    bottom: 100px;
    z-index: 100;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #fff;
    width: 40%;
    min-width: 500px;
}
